import * as React from "react";
import { useEffect } from "react";
import { navigate } from "gatsby";
import PageHelmet from "../components/common/helmet";

const ToolsIndexPage: React.FC = () => {
  useEffect(() => {
    navigate("base64");
  }, []);

  return (
    <>
      <PageHelmet title="Redirect" />
    </>
  );
};

export default ToolsIndexPage;